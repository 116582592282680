<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd='DEVICE_CD'
            type="none"
            itemText="codeName"
            itemValue="code"
            name="deviceType"
            label="시스템 구분"
            v-model="searchParam.deviceType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="0M"
            label="기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            type="user"
            label="사용자"
            beforeText=""
            name="userId"
            v-model="searchParam.userId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      title="메뉴 로그 목록"
      tableId="menuLog"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'menu-log',
  data() {
    return {
      searchParam: {
        period: [],
        userId: '',
        deviceType: 'P',
        plantCd: null,
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:160px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'menuNm',
            field: 'menuNm',
            label: '메뉴명',
            align: 'left',
            sortable: false,
            style: 'width:200px',
          },
          {
            name: 'menuPath',
            field: 'menuPath',
            label: '메뉴 경로',
            align: 'left',
            sortable: false,
            style: 'width:360px',
          },
          {
            name: 'url',
            field: 'url',
            label: 'URL',
            align: 'left',
            sortable: false,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '접속일시',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sys.log.menulog.list.url
      // code setting
      // list setting
      // this.searchParam.userId = this.$store.getters.user.userId
      this.searchParam.period = [this.$comm.getPrevMonth(1) + '-01', this.$comm.getToday()];
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
